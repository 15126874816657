<template>
  <div class="dataset-detail container">
    <!-- <el-header>
      <el-page-header
        @back="goBack"
        :content="this.dimensionName"
      ></el-page-header>
    </el-header> -->
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/dataset' }"
          >数据集</el-breadcrumb-item
        >
        <el-breadcrumb-item>数据集详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="top">
      <div class="title d-flex">
        <div class="text">{{ this.dimensionName }}</div>

        <div class="right d-flex">
          <div class="time">
            <i class="el-icon-view" style="margin-right: 12px"></i
            ><span class="">2024</span>
          </div>

          <div class="link">
            <a :href="url" class="text" target="_blank">
              <i class="el-icon-link"></i
              ><span class="value-text">官网</span></a
            >
          </div>
        </div>
      </div>

      <div class="intro">{{ intro }}</div>
    </div>
    <div class="sub-title bottom-line d-flex">
      <div class="gap"></div>
      <div>数据集详情</div>
    </div>
    <el-row class="list">
      <el-col :span="6" class="col"
        >维度： <span class="value-text">{{ dimension }}</span></el-col
      >
      <el-col :span="6" class="col"
        >语言： <span class="value-text">{{ language }}</span></el-col
      >
      <el-col :span="6" class="col"
        >来源： <span class="value-text">{{ source }}</span></el-col
      >
    </el-row>
    <el-row class="list">
      <el-col :span="6" class="col"
        >数据规模：<span class="value-text">{{ scale }}</span></el-col
      >
      <el-col :span="6" class="col"
        >更新时间：<span class="value-text">{{ time }}</span></el-col
      >
      <el-col :span="6" class="col" v-if="evaluation"
        >评估方法：<span class="value-text">{{ evaluation }}</span></el-col
      >
    </el-row>

    <div class="sub-title d-flex">
      <div class="gap"></div>
      <div>例子</div>
    </div>
    <highlightjs :code="example" class="hljs"></highlightjs>
  </div>
</template>
  
  <script>
import originDatasetList from "@/assets/data/dataset/dataset.json";
export default {
  data() {
    return {
      dimensionName: "",
      DataList: [],
      dimension: "",
      source: "",
      time: "",
      scale: "",
      intro: "",
      url: "",
      language: "",
      evaluation:"",
      example: "",
    };
  },
  created() {
    this.dimensionName = this.$route.query.name;
  },
  mounted() {
    // 筛选数据
    this.DataList = originDatasetList.dataset.filter(
      (item) => item.name === this.dimensionName
    );

    console.log("this.DataList", this.DataList);

    // 提取并处理多个属性
    const extractAndFormat = (key) =>
      this.DataList.map((item) => item[key])
        .join(", ") // 使用逗号和空格连接数组元素，避免了手动去除前后括号的问题
        .trim(); // 去除可能存在的首尾空白字符

    this.dimension = extractAndFormat("dimension");
    this.source = extractAndFormat("source");
    this.time = extractAndFormat("time");
    this.scale = extractAndFormat("scale");
    this.intro = extractAndFormat("intro");
    this.url = extractAndFormat("url");
    this.language = extractAndFormat("language");
    this.evaluation = extractAndFormat("evaluation");

    // 特殊处理 example 属性，保留换行和缩进
    let exp = this.DataList.map((item) => item["example"]);
    this.example = JSON.stringify(exp, null, 2).slice(2, -2); // 调整 slice 参数以移除多余的引号
  },

  methods: {
    goBack() {
      this.$router.push({ name: "dataset" });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.dataset-detail {
  margin-top: 88px;

  .breadcrumb {
    margin: 24px 0;
  }

  .top {
    border-bottom: 1px solid transparent; /* 防止 margin 重叠 */
    .title {
      font-weight: 500;
      font-size: 24px;
      color: #1d2129;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right {
        .time {
          font-size: 14px;
          color: #4e5969;
          margin-right: 39px;
        }
      }
      .link {
        font-weight: 400;
        font-size: 14px;
        .text {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #165dff;

          i {
            margin-right: 2px;
          }
        }
      }
    }

    .intro {
      font-weight: 400;
      font-size: 14px;
      color: #4e5969;
      line-height: 22px;
      text-align: left;
      margin: 24px 0;
    }
  }

  .list {
    margin-top: 24px;
  }
}
.sub-title {
  margin-top: 32px;
  margin-left: 16px;
  padding-bottom: 16px;
  font-weight: 500;
  font-size: 20px;
  color: #1d2129;
  .gap {
    width: 4px;
    height: 16px;
    background: #165dff;
    border-radius: 2px 2px 2px 2px;
    margin-right: 16px;
  }
}
.bottom-line {
  border-bottom: 1px solid #e5e6eb;
}
.hljs {
  border: 0;
  font-size: 12px;
  display: block;
  padding: 1px;
  margin: 0;
  width: 100%;
  overflow: auto;
  max-height: 700px;
  font-weight: 200;
  //   color: rgba(255, 255, 255, 0.98);
  white-space: pre-wrap;
  position: relative;
  text-align: left;
  margin-bottom: 30px;
}
.col {
  text-align: left;
  color: #86909c;
  .value-text {
    color: #1d2129;
  }
}
</style>